<template>
    <div class="blog-page" :class="pageClass">
        <HeroBlog v-bind="heroBlog" :title="title" :tagline="heroBlog?.tagline" />
        <SubnavBlock
            :navigationLinks="subNav?.navigationLinks"
            :socialNetworks="subNav?.socialNetworks ?? socialNetworks"
            :button="subNav?.button"
            :pageLanguage="pageLanguage"
        />

        <section v-if="heroContent?.length" class="hero-content-tier-wrapper">
            <div class="hero-content-tier" :class="{ '--display-feed': heroContent?.length > 3 }">
                <div class="featured-card">
                    <ContentCardBlock
                        :related="heroContent[0]"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </div>

                <div class="two-up-cards">
                    <ContentCardBlock
                        :related="heroContent[1]"
                        :hideDescription="true"
                        size="25"
                        :imageColorVariant="imageColorVariant"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                    <hr />
                    <ContentCardBlock
                        :related="heroContent[2]"
                        :hideDescription="true"
                        size="25"
                        :imageColorVariant="imageColorVariant"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </div>

                <div v-if="heroContent?.length > 3" class="feed-cards">
                    <ContentCardBlock
                        :related="heroContent[3]"
                        :hideDescription="true"
                        :hideImage="true"
                        size="0"
                        :imageColorVariant="imageColorVariant"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                    <hr />
                    <ContentCardBlock
                        :related="heroContent[4]"
                        :hideDescription="true"
                        :hideImage="true"
                        size="0"
                        :imageColorVariant="imageColorVariant"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                    <hr />
                    <ContentCardBlock
                        :related="heroContent[5]"
                        :hideDescription="true"
                        :hideImage="true"
                        size="0"
                        :imageColorVariant="imageColorVariant"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </div>

                <div class="about">
                    <Typography variant="h4-display">{{ headline }}</Typography>
                    <RichTextElements :elements="dek" body-style="serif-small" color="" />
                </div>
            </div>
        </section>

        <!-- <section v-if="featuredPodcastEpisode" class="featured-podcast-wrapper">
            <div class="featured-podcast">
                <BlogPodcastOverview v-bind="{ ...featuredPodcastEpisode.parent }" :accentColor="accentColor" />

                <div class="podcast-embed podcast-preview" v-html="featuredPodcastEpisode.embedCode" />
            </div>
        </section> -->

        <section v-if="featuredContentItems?.length" class="featured-content-wrapper">
            <div class="featured-content">
                <ContentCardBlock
                    v-for="(item, index) in featuredContentItems"
                    :related="item"
                    :key="index"
                    :hideDescription="true"
                    size="25"
                    :imageColorVariant="imageColorVariant"
                    :showPublicationDate="true"
                    :pageLanguage="pageLanguage"
                />
            </div>
        </section>

        <section v-if="newsletterForm?.displaySubscribeSnackbar">
            <div>
                Newsletter Form
                <component :is="formComponent" />
            </div>
        </section>

        <StreamTierFeed :streamTiered="streamTiered" />

        <IndexTier
            :title="`${$t('All', 1, { locale: pageLanguage })}`"
            :contentType="contentType"
            :pageId="id"
            :pageLanguage="pageLanguage"
            :renderedFilters="renderedFilters"
        >
            <template #results="{ results }">
                <ContentCardBlock
                    v-for="(result, index) in results"
                    :key="index"
                    :related="result"
                    orientation="wide"
                    context="inline"
                    class="result"
                    :hideDateCard="true"
                    :imageColorVariant="imageColorVariant"
                    :showPublicationDate="true"
                    :pageLanguage="pageLanguage"
                />
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
const headerAttributesStore = useHeaderAttributesStore();
const { pageLanguage } = usePageDataStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});

const props = defineProps({
    dek: {
        type: Object,
    },
    headline: {
        type: String,
        default: '',
    },
    newsletterForm: {
        type: Object,
    },
    heroBlog: {
        type: Object,
    },
    id: {
        type: String,
    },
    path: {
        type: String,
    },
    accentColor: {
        type: String,
    },
    slug: {
        type: String,
    },
    socialNetworks: {
        type: Object,
    },
    subNav: {
        type: Object,
    },
    title: {
        type: String,
    },
    heroContentTier: {
        type: Object,
    },
    featuredContent: {
        type: Object,
    },
    featuredPodcastEpisode: {
        type: Object,
    },
    streamTiered: {
        type: Object,
    },
    contentType: {
        type: String,
        default: '',
    },
    locale: {
        type: String,
        default: '',
    },
});

const renderedFilters = ['topics', 'regions', 'experts', 'years'];

provide('colorOverride', props.accentColor);

const pageClass = computed(() => {
    return pageLanguage === 'ar' ? 'direction-rtl' : '';
});

const formComponent =
    props.newsletterForm && props.newsletterForm.formName
        ? getNewsletterFormComponent(props.newsletterForm.formName)
        : null;

const heroContent = computed(() => getContentFeedItems(props.heroContentTier?.feed, props.heroContentTier?.dynamic));

const heroContentClasses = computed(() => {
    return {
        '--display-feed': heroContent.length > 3,
    };
});

const hasHeroFeed = computed(() => {
    return heroContent.length;
});

const featuredContentItems = computed(() =>
    getContentFeedItems(props.featuredContent?.feed, props.featuredContent?.dynamic),
);

const imageColorVariant = computed(() => {
    return props.path === '/russia-eurasia/politika' ? 'desaturate' : '';
});

const blogImage = computed(() => {
    if (props.title === 'Diwan') {
        return 'https://carnegieendowment.org/static/media/images/Diwan-Landing%20header.png';
    } else if (props.title === 'ديوان') {
        return 'https://carnegieendowment.org/static/media/images/Diwan-Landing%20header.png';
    } else if (props.title === 'Carnegie Politika') {
        return 'https://carnegieendowment.org/static/media/images/Politika-Landing%20header.png';
    } else if (props.title === 'Карнеги Politika') {
        return 'https://carnegieendowment.org/static/media/images/Politika-Landing%20header.png';
    } else if (props.title === 'China Financial Markets') {
        return 'https://carnegieendowment.org/static/media/images/CFM-Landing%20header.png';
    } else if (props.title === 'Sada') {
        return 'https://carnegieendowment.org/static/media/images/Sada-Landing%20header.png';
    } else if (props.title === 'Strategic Europe') {
        return 'https://carnegieendowment.org/static/media/images/Strategic%20Europe-Landing%20header.png';
    } else if (props.title === 'Emissary') {
        return 'https://carnegieendowment.org/static/media/images/Emmisary-Landing header.png';
    }
});
useSeoMeta({
    ogImage: blogImage,
    twitterImage: blogImage,
});

provide('imageColorVariant', props.path === '/russia-eurasia/politika' ? 'desaturate' : '');

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Blog Homepage',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
});
</script>

<style lang="scss" scoped>
.hero-content-tier {
    @include content-section;
    @include padding-tb(1.6rem);
    @include grid;

    @include media-query(phone) {
        @include padding-lr(0);
    }

    position: relative;

    &:after {
        display: block;
        content: '';
        position: absolute;
        width: calc(100% - 2 * (var(--page-margin)));
        height: 0;
        bottom: 0;
        inset-inline-start: var(--page-margin);
        border-bottom: 0.1rem solid palette(border-light);
    }

    .featured-card {
        grid-area: featured;
        position: relative;

        .content-card-block {
            @include media-query(phone) {
                border-bottom: 0.1rem solid palette(border-light);
            }

            @include media-query(not-phone) {
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 100%;
                    top: 0;
                    inset-inline-end: calc(-1 * (var(--col-gutter) / 2));
                    border-inline-end: 0.1rem solid palette(border-light);
                }
            }

            @include media-query(full) {
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 100%;
                    top: 0;
                    inset-inline-start: calc(-1 * (var(--col-gutter) / 2));
                    border-inline-start: 0.1rem solid palette(border-light);
                }
            }
        }
    }

    .two-up-cards {
        grid-area: cards;
    }

    .feed-cards {
        grid-area: feed;
    }

    .about {
        // POST LAUNCH: this causes a bug
        // $bgcolor: v bind ('props.accentColor');
        grid-area: about;
        position: relative;
        padding: 2.4rem;

        &:before {
            display: block;
            content: '';
            // background-color: $bgcolor;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            opacity: 0.1;
        }

        @include media-query(full) {
            @include padding-tb(4.4rem);
        }

        @include media-query(phone) {
            @include margin-lr(var(--col-gutter));
        }
    }

    &:not(.--display-feed) {
        grid-template-columns: 100%;
        grid-template-areas:
            'featured'
            'about'
            'cards';

        @include media-query(full) {
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-areas: 'cards featured about';
        }
    }

    &.--display-feed {
        grid-template-columns: 100%;
        grid-template-areas:
            'featured'
            'cards'
            'feed'
            'about';

        @include media-query(tablet) {
            grid-template-columns: 2fr 1fr;
            grid-template-areas:
                'featured feed'
                'cards cards'
                'about about';
        }

        @include media-query(full) {
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-areas:
                'cards featured feed'
                'cards featured about';
            .two-up-cards {
                max-width: 25vw;
            }
            .feed-cards {
                max-width: 25vw;
            }
        }
    }
}

.featured-podcast {
    @include content-section;
    @include padding-tb(1.6rem);
    @include grid;

    @include media-query(phone) {
        @include padding-lr(0);
        display: block;
    }

    position: relative;

    &:after {
        display: block;
        content: '';
        position: absolute;
        width: calc(100% - 2 * (var(--page-margin)));
        height: 0;
        bottom: 0;
        inset-inline-start: var(--page-margin);
        border-bottom: 0.1rem solid palette(border-light);
    }

    .podcast-preview {
        grid-column: 7 / span 6;
        padding: vertical-space(1);
        background: color(grey, light);

        @include media-query(phone) {
            margin: var(--col-gutter);
        }
    }
}

.featured-content {
    @include content-section;

    @include media-query(phone) {
        @include padding-lr(0);
    }

    @include padding-tb(var(--col-gutter));

    display: grid;
    gap: var(--col-gutter);

    @include media-query(phone) {
        grid-template-columns: 1fr;
    }

    @include media-query(tablet) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
    }

    @include media-query(full) {
        grid-template-columns: repeat(4, 1fr);
    }

    .content-card-block {
        &:not(:last-of-type) {
            &:after {
                display: block;
                content: '';
                position: absolute;

                @include media-query(phone) {
                    width: calc(100% - (2 * var(--col-gutter)));
                    height: 0%;
                    bottom: 0;
                    inset-inline-start: var(--col-gutter);
                    border-bottom: 0.1rem solid palette(border-light);
                }

                @include media-query(full) {
                    width: 0;
                    height: 100%;
                    top: 0;
                    inset-inline-end: calc(-1 * (var(--col-gutter) / 2));
                    border-inline-end: 0.1rem solid palette(border-light);
                }
            }
        }
    }
}

hr {
    border-bottom: 0.1rem solid palette(border-light);
    @include margin-lr(var(--col-gutter));
}

.desaturate {
    img {
        filter: grayscale(1);
    }
}
</style>
